import React from "react";

const Doc = ({ doc, onRemove }) => (
  <div className="bg-white rounded p-4 pl-6 flex items-center justify-between border-2 border-gray-200">
    <div className="flex text-lg items-center">
      <i className="fa fa-file-pdf text-brand-500 mr-2" />
      {doc.docType}
      {!doc.id && (
        <div className="rounded text-uppercase text-white bg-orange-500 px-2 text-sm font-bold ml-3">
          NEW
        </div>
      )}
    </div>
    <div className="flex space-x-2">
      {doc.id && doc.url && (
        <i
          onClick={() => window.open(doc.url, "_blank").focus()}
          className="fa fa-eye text-brand-500 p-3 px-3 hover:bg-blue-50 cursor-pointer rounded-md"
        ></i>
      )}
      <i
        onClick={onRemove}
        className="fa fa-times text-red-600 p-3 px-4 hover:bg-red-50 cursor-pointer rounded-md"
      ></i>
    </div>
  </div>
);

const DocAdder = ({ onAdd, docTypes }) => {
  const [localMode, setLocalMode] = React.useState(false);
  const [fileType, setFileType] = React.useState(docTypes[0]);

  const [localFile, setLocalFile] = React.useState(undefined);
  const [remoteUrl, setRemoteUrl] = React.useState("");

  return (
    <div className="flex flex-col mt-4">
      <div className="flex flex-row items-center mb-4 space-x-2">
        <div
          onClick={() => setLocalMode(false)}
          className={`cursor-pointer select-none rounded-lg p-2 px-3 ${
            localMode ? "bg-white text-black" : "bg-brand-500 text-white"
          }`}
        >
          From URL
        </div>
        <div
          onClick={() => setLocalMode(true)}
          className={`cursor-pointer select-none rounded-lg p-2 px-3 ${
            localMode ? "bg-brand-500 text-white" : "bg-white text-black"
          }`}
        >
          From Computer
        </div>
      </div>

      <div className="flex flex-col space-y-4 md:space-y-0 md:flex-row md:space-x-4 items-stretch">
        {localMode && (
          <input
            type="file"
            accept="application/pdf"
            onChange={(e) => setLocalFile(e.target.files[0])}
            className="large-text-input w-full flex-grow border-gray-400"
            placeholder="Browse to find your document..."
          />
        )}
        {!localMode && (
          <input
            value={remoteUrl}
            onChange={(e) => setRemoteUrl(e.target.value)}
            className="large-text-input w-full flex-grow border-gray-400"
            placeholder="Full link of PDF document (e.g., https://kemppi.com/files/3424134314.pdf"
          />
        )}
        <div className="border-2 border-gray-400 px-6 py-4 rounded flex flex-row items-center justify-center bg-white">
          <select
            value={fileType}
            className="border-none bg-white text-lg pr-2"
            onChange={(e) => setFileType(e.target.value)}
            placeholder="Type"
          >
            {docTypes.map((docType) => (
              <option key={docType} value={docType}>
                {docType}
              </option>
            ))}
          </select>
        </div>
        <button
          onClick={() => {
            onAdd({
              docType: fileType,
              source: localMode ? localFile : remoteUrl,
            });
            setRemoteUrl("");
            setLocalFile(undefined);
          }}
          className="bg-gray-100 border-2 border-gray-400 text-lg p-4 px-6 rounded shadow whitespace-nowrap"
        >
          <i className="fa-duotone fa-regular fa-upload mr-4 text-gray-400"></i>
          Add Document
        </button>
      </div>
    </div>
  );
};

export const DocList = ({ docs, docTypes, onChange }) => (
  <div className="flex flex-col items-stretch mt-4">
    <div className="grid grid-cols-1 md:grid-cols-2 gap-3">
      {docs.map((doc, docIndex) => (
        <Doc
          key={docIndex}
          doc={doc}
          onRemove={() =>
            onChange([...docs.slice(0, docIndex), ...docs.slice(docIndex + 1)])
          }
        />
      ))}
    </div>
    <DocAdder docTypes={docTypes} onAdd={(doc) => onChange([...docs, doc])} />
  </div>
);
