import React from "react";
import Moment from "react-moment";

export const Header = () => (
  <div className="flex flex-col md:flex-row items-center md:justify-between">
    <div className="hidden md:flex flex-row items-center">
      <i className="fa-duotone fa-regular fa-calendar mr-3 text-white"></i>
      <Moment
        format="ddd, Do MMM"
        className="text-white font-mono text-lg select-none"
      />
    </div>
    <img className="w-48 select-none" src="/weldmet-logo.svg" />
    <div className="hidden md:flex flex-row items-center">
      <i className="fa-duotone fa-regular fa-clock mr-2 text-white"></i>
      <Moment
        interval={1000}
        format="LTS"
        className="text-white text-xl font-mono select-none"
      />
    </div>
  </div>
);
