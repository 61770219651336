import React from "react";
import { DriverMarker } from "./DriverMarker";

export const DriverIcon = ({
  driver,
  machine,
  index,
  selectionMode,
  isSelected,
  onMouseEnter,
  onMouseLeave,
}) => (
  <div
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    className={`bg-white transition-opacity duration-500 ${
      selectionMode ? (isSelected ? "opacity-100" : "opacity-50") : "opacity-80"
    } rounded shadow-lg p-2 flex flex-row items-center`}
  >
    <DriverMarker
      machine={machine}
      large
      colour={driver.data.attributes.avatar_colour}
      text={driver.data.attributes.name}
    />
    <div className="ml-3 flex flex-col items-start">
      <div className="text-gray-900 text-sm font-bold leading-tight">
        {driver.data.attributes.friendly_name}
        {!machine && <i className="fa-solid fa-user-hard-hat ml-1"></i>}
      </div>
      <div className="flex flex-row items-center">
        <i
          className="fa-solid fa-signal text-xs leading-tight mr-1"
          style={{
            fontSize: "9px",
            color: driver.data.attributes.signal_strength_color,
          }}
        ></i>
        <div className="text-gray-900 text-sm leading-tight select-none">
          {driver.data.attributes.loc_town}
        </div>
      </div>
    </div>
  </div>
);
