import React from "react";
import Moment from "react-moment";
import { TagTray } from "../TagTray";

export const DeliveryDetailTray = ({ delivery, onCancel }) => (
  <div
    className="lg:col-span-4 flex flex-col items-stretch"
    style={{ height: "calc(100vh - 110px)" }}
  >
    <div className="flex flex-row items-center justify-between mb-3">
      <div className="text-gray-200 uppercase font-bold select-none">
        <i className="fa fa-info-circle mr-2 text-gray-200"></i>
        Selected Delivery
      </div>
      <div
        onClick={onCancel}
        className="mr-2 uppercase cursor-pointer text-white text-sm font-bold flex flex-row items-center"
      >
        <i className="fa fa-times text-white mr-2" />
        Close
      </div>
    </div>

    <div className="flex flex-col items-stretch pr-2 overflow-y-auto">
      <div className="rounded bg-white border-2 opacity-80 border-gray-300 flex flex-col items-stretch p-3 shadow-lg">
        <div className="flex flex-row items-center justify-between leading-tight mb-1">
          <span>
            <span className="text-lg font-bold text-gray-800">
              {delivery.data.attributes.address_0}
            </span>
            <TagTray delivery={delivery} />
          </span>
          <div className="ml-2 text-lg text-gray-800 flex flex-row items-center">
            <i className="fa-solid fa-heading mr-2"></i>
            {delivery.data.attributes.hansa_id}
          </div>
        </div>
        <div className="text-sm">
          {delivery.data.attributes.customer_code} • Note created{" "}
          <Moment fromNow>{delivery.data.attributes.hansa_created_at}</Moment>
        </div>
        <div className="rounded border-2 border-gray-400 bg-gray-100 mt-3 p-2 leading-tight">
          {delivery.data.attributes.address_0}
          {delivery.data.attributes.address_1 && (
            <>
              <br />
              {delivery.data.attributes.address_1}
            </>
          )}
          {delivery.data.attributes.address_2 && (
            <>
              <br />
              {delivery.data.attributes.address_2}
            </>
          )}
          {delivery.data.attributes.address_3 && (
            <>
              <br />
              {delivery.data.attributes.address_3}
            </>
          )}
          {delivery.data.attributes.address_4 && (
            <>
              <br />
              {delivery.data.attributes.address_4}
            </>
          )}
          {delivery.data.attributes.address_5 && (
            <>
              <br />
              {delivery.data.attributes.address_5}
            </>
          )}
        </div>
      </div>

      {delivery.data.attributes.comment && (
        <div className="rounded mt-2 bg-white border-2 opacity-80 border-gray-300 flex flex-col items-stretch p-3 shadow-lg">
          <div className="flex flex-row items-center justify-between">
            <span>
              <span className="text-lg font-bold text-gray-800">Comment</span>
            </span>
          </div>
          <div className="rounded border-2 border-gray-400 bg-gray-100 mt-3 p-2">
            {delivery.data.attributes.comment}
          </div>
        </div>
      )}

      <div className="rounded mt-2 bg-white border-2 opacity-80 border-gray-300 flex flex-col items-stretch p-3 shadow-lg">
        <div className="flex flex-row items-center justify-between">
          <span>
            <span className="text-lg font-bold text-gray-800">
              {delivery.data.attributes.items
                .filter((item) => item.item_code && item.ordered_quantity)
                .map((item) => parseInt(item.ordered_quantity))
                .reduce((acc, total) => acc + total, 0)}{" "}
              Item(s) Ordered
            </span>
          </span>
        </div>
        <div className="grid gap-y-4 grid-cols-12 mt-3">
          <>
            <div className="col-span-2 uppercase text-sm font-bold pb-2 border-b-2 border-gray-300">
              Code
            </div>
            <div className="col-span-6 uppercase text-sm font-bold pb-2 border-b-2 border-gray-300">
              Name
            </div>
            <div className="col-span-2 uppercase text-sm font-bold text-right pb-2 border-b-2 border-gray-300">
              Ordered
            </div>
            <div className="col-span-2 uppercase text-sm font-bold text-right pb-2 border-b-2 border-gray-300">
              Stock
            </div>
          </>
          {delivery.data.attributes.items.length === 0 ? (
            <div className="col-span-full flex flex-col items-center text-center my-4">
              <i className="far fa-sparkles mb-2 text-xl"></i>
              No Items Found
            </div>
          ) : (
            delivery.data.attributes.items.map((item, index) => {
              const isDeliveryLine =
                item && item.item_code && item.item_code.startsWith("M-DEL-");
              return (
                <React.Fragment key={`item-row-${index}`}>
                  <div className="col-span-2 leading-tight text-sm font-mono pr-2">
                    {item.item_code && (
                      <a
                        className={
                          isDeliveryLine
                            ? ""
                            : "cursor-pointer text-blue-700 underline"
                        }
                        target="_blank"
                        href={`https://shop.weldmet.co.uk/products/${item.item_code}`}
                      >
                        {item.item_code}
                      </a>
                    )}
                    {item.shelf_code && (
                      <div className="text-xs mt-1 text-gray-600">
                        <i className="fa-solid fa-map-marker-alt mr-1 text-gray-600"></i>
                        Shelf:{" "}
                        <span className="text-gray-600 font-mono">
                          {item.shelf_code}
                        </span>
                      </div>
                    )}
                  </div>
                  <div
                    className={`col-span-6 text-sm leading-tight ${
                      isDeliveryLine ? "italic" : ""
                    }`}
                  >
                    {isDeliveryLine && (
                      <i className="fa-duotone fa-regular fa-truck mr-1"></i>
                    )}
                    {item.item_description}
                  </div>
                  <div className="col-span-2 leading-tight text-sm text-right font-mono font-bold">
                    {!isDeliveryLine && item.ordered_quantity && (
                      <>×{item.ordered_quantity}</>
                    )}
                  </div>
                  <div className="col-span-2 leading-tight text-sm text-right font-mono font-bold">
                    {!isDeliveryLine && (
                      <>
                        {item.ordered_quantity &&
                          item.stocked_quantity &&
                          parseInt(item.ordered_quantity) >
                            parseInt(item.stocked_quantity) && (
                            <i className="fa-solid fa-exclamation-circle text-yellow-500 mr-1"></i>
                          )}
                        {item.stocked_quantity && <>×{item.stocked_quantity}</>}
                      </>
                    )}
                  </div>
                </React.Fragment>
              );
            })
          )}
        </div>
      </div>
    </div>
  </div>
);
