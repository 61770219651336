import { IssueFlagger } from "./IssueFlagger";
import React from "react";

export const Header = ({
  manufacturer,
  mpn,
  title,
  code,
  shopifyEditorLink,
  shopifyViewLink,
  manufacturerLink,
  issues,
  onChangeIssues,
}) => (
  <div className="flex items-center justify-between pb-6 border-b-2 border-b-gray-400">
    <div className="flex flex-col items-start pr-4">
      <div className="leading-tight">
        {mpn
          ? `${manufacturer || "Manufacturer"}'s Code: ${mpn}`
          : manufacturer}
      </div>
      <div className="my-1 text-2xl font-bold leading-tight">{title}</div>
      <div className="leading-tight">Our Code: #{code}</div>
    </div>
    <div className="flex flex-col space-y-2 select-none lg:space-y-0 lg:flex-row lg:space-x-2 whitespace-nowrap">
      <IssueFlagger options={issues} onChange={onChangeIssues} />

      {/* {manufacturerLink && (
        <a
          className="p-2 px-4 text-gray-800 bg-white border-2 border-gray-200 rounded shadow-sm cursor-pointer"
          href={manufacturerLink}
          target="_blank"
        >
          <i className="mr-2 text-green-500 far fa-browser"></i>
          Manufacturer's page
        </a>
      )}
      {shopifyEditorLink && (
        <a
          className="p-2 px-4 text-gray-800 bg-white border-2 border-gray-200 rounded shadow-sm cursor-pointer"
          href={shopifyEditorLink}
          target="_blank"
        >
          <i className="mr-2 text-green-700 fa-brands fa-shopify"></i>
          Edit in Shopify
        </a>
      )}
      {shopifyViewLink && (
        <a
          className="p-2 px-4 text-gray-800 bg-white border-2 border-gray-200 rounded shadow-sm cursor-pointer"
          href={shopifyViewLink}
          target="_blank"
        >
          <i className="mr-2 far fa-globe text-brand-500"></i>
          View on online store
        </a>
      )} */}
    </div>
  </div>
);
