import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { ContentState, EditorState } from "draft-js";
import { PhotoRating, judgeByWidth } from "./subcomponents/PhotoRating";

import { DocList } from "./subcomponents/DocList";
import { Editor } from "react-draft-wysiwyg";
import { FeedbackMatrix } from "./subcomponents/FeedbackMatrix";
import { Header } from "./subcomponents/Header";
import { PointBoard } from "./subcomponents/PointBoard";
import React from "react";
import { Subcollections } from "./subcomponents/Subcollections";
import { convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";

function htmlToState(html) {
  const blocksFromHtml = htmlToDraft(html);
  const { contentBlocks, entityMap } = blocksFromHtml;
  const contentState = ContentState.createFromBlockArray(
    contentBlocks,
    entityMap
  );
  return EditorState.createWithContent(contentState);
}

const stateToHtml = (state) => {
  const rawContentState = convertToRaw(state.getCurrentContent());
  return draftToHtml(rawContentState);
};

const ItemPage = (props) => {
  // Initialize editor
  const [currentTitle, setCurrentTitle] = React.useState(props.title);
  const [editorState, setEditorState] = React.useState(
    htmlToState(props.description)
  );
  const [currentSummary, setCurrentSummary] = React.useState(props.summary);
  const [currentPhotos, setCurrentPhotos] = React.useState(props.photos);
  const [currentSubcollections, setCurrentSubcollections] = React.useState(
    props.subcollections
  );
  const [currentMPN, setCurrentMPN] = React.useState(props.mpn);
  const [currentManLink, setCurrentManLink] = React.useState(
    props.manufacturerLink
  );
  const [currentWarranty, setCurrentWarranty] = React.useState(props.warranty);
  const [currentItemType, setCurrentItemType] = React.useState(props.itemType);
  const [currentDocs, setCurrentDocs] = React.useState(props.docs);

  const [issues, setIssues] = React.useState(
    [
      {
        slug: "no-identifying-info",
        label: "Not enough unique information to find product",
      },
      {
        slug: "not-online",
        label: "There is no information about product online",
      },
      {
        slug: "no-photos",
        label: "There are no photos of this product online",
      },
      {
        slug: "no-docs",
        label: "There is no documentation for this product online",
      },
    ].map((issue) => ({ ...issue, checked: props.issues.includes(issue.slug) }))
  );

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
  };

  const generateFeedback = (
    title,
    descriptionHtml,
    photos,
    mpn,
    manufacturerLink,
    docs
  ) => {
    const photoJudgements = photos.map((photo) => ({
      position: photo.position,
      quality: judgeByWidth(photo.width).label,
    }));

    const firstPhoto = photoJudgements.find((j) => j.position === 1);

    return [
      mpn && {
        field: "title",
        text: `Mentions the manufacturer's part number (MPN): ${mpn}`,
        right: mpn && title.includes(mpn),
        fixer: () => setCurrentTitle(`${currentTitle} (${currentMPN})`),
      },
      {
        field: "title",
        text: "Every first letter is capitalized.",
        right: title && title.split(" ").every((x) => x.match(/^[^a-wy-z]/)),
        fixer: () =>
          setCurrentTitle(
            currentTitle
              .replace(/(\b[a-wy-z](?!\s))/g, (c) => c.toUpperCase())
              .replace(/\s+$/, "")
          ),
      },
      title && {
        field: "title",
        text: "Contains no double spaces.",
        right: !title.match(/\s{2,}/),
        fixer: () => setCurrentTitle(currentTitle.replace(/\s{2,}/g, " ")),
      },
      title && {
        field: "title",
        text: "Dimensions are separated properly with 'x'.",
        right: !title.match(/\dx\d/),
        fixer: () =>
          setCurrentTitle(
            currentTitle
              .replace(/(\d)\s?x\s?(\d)/g, "$1 x $2")
              .replace(/\sx\s/g, " x ")
          ),
      },

      title && {
        field: "title",
        text: "All area measurements are formatted correctly.",
        right: !(
          title.match(
            /([0-9])\s?(sq|square|squared).?(cm|mm|m|mtr|metres|mtrs|metre|in|ins|inch)\b/i
          ) ||
          title.match(
            /([0-9])\s?(cm|mm|m|mtr|metres|mtrs|metre|in|ins|inch)\s(squared|square)\b/i
          )
        ),
        fixer: () => {
          setCurrentTitle(
            currentTitle
              .replace(/me?tre?s?/g, "m")
              .replace(/inch(es?)?/g, "in")
              .replace(
                /([0-9])\s?(sq|square|squared).?(cm|mm|m|mtr|metres|mtrs|metre|in|ins|inch)\b/gi,
                "$1$3 sq."
              )
              .replace(
                /([0-9])\s?(cm|mm|m|mtr|metres|mtrs|metre|in|ins|inch)\s(squared|square)\.?\b/gi,
                "$1$2 sq."
              )
          );
        },
      },

      title && {
        field: "title",
        text: "All distance measurements are formatted correctly.",
        right: !(
          title.match(/([0-9])\s(cm|mm|m|in|ins|ft)\b/i) ||
          title.match(/([0-9])(mtr|metres|mtrs|metre|inch|foot|feet)\b/i)
        ),
        fixer: () => {
          setCurrentTitle(
            currentTitle
              .replace(/([0-9])\s?(metres|metre|mtr|m)\b/gi, "$1m")
              .replace(/([0-9])\s?(centimetres|centimetre|cm)\b/gi, "$1cm")
              .replace(/([0-9])\s?(milimetres|milimetre|mm)\b/gi, "$1mm")
              .replace(/([0-9])\s?(inches|inch|ins|in")\b/gi, "$1in")
              .replace(/([0-9])\s?(feet|foot|ft")\b/gi, "$1ft")
          );
        },
      },

      title && {
        field: "title",
        text: "All weight and volume measurements are formatted correctly.",
        right: !(
          title.match(/([0-9])\s(ml|l|g|kg|kgs|lb|lbs)\b/i) ||
          title.match(
            /([0-9])(milliliters|milliliter|litres|litre|liters|liter|grams|gram|kilograms|kilos|kilo|pounds|pound)\b/i
          )
        ),
        fixer: () => {
          setCurrentTitle(
            currentTitle
              .replace(/([0-9])\s?(milliliters|milliliter|ml)\b/gi, "$1ml")
              .replace(/([0-9])\s?(litres|litre|liters|liter|l)\b/gi, "$1l")
              .replace(/([0-9])\s?(grams|gram|g)\b/gi, "$1g")
              .replace(/([0-9])\s?(kilograms|kilos|kilo|kgs|kg)\b/gi, "$1kg")
              .replace(/([0-9])\s?(pounds|pound|lbs|lb")\b/gi, "$1lbs")
          );
        },
      },

      title && {
        field: "title",
        text: "Ampage and voltage are formatted correctly.",
        right: !(
          title.match(/([0-9])\s?(volt|Volt|volts|Volts|v)\b/) ||
          title.match(/([0-9])\s?(amp|Amp|amps|Amps|a)\b/)
        ),
        fixer: () => {
          setCurrentTitle(
            currentTitle
              .replace(/([0-9])\s?(volt|volts|v)\b/gi, "$1V")
              .replace(/([0-9])\s?(amp|amps|a)\b/gi, "$1A")
          );
        },
      },
      {
        field: "description",
        text: "Provides some description of the product.",
        right: descriptionHtml && descriptionHtml.length > 8,
      },
      descriptionHtml &&
        descriptionHtml.length > 8 && {
          field: "description",
          text: "No line breaks (carriage return / paragraph breaks only).",
          right: !descriptionHtml.match(/<br>/),
          fixer: () =>
            setEditorState(
              htmlToState(descriptionHtml.replaceAll("<br>", "</p> <p>"))
            ),
        },
      descriptionHtml &&
        descriptionHtml.length > 8 && {
          field: "description",
          text: "Sentences begin with a capital letter.",
          right:
            !descriptionHtml.match(/<p>[a-z]/) &&
            !descriptionHtml.match(/\w\. [a-z]/),
          fixer: () =>
            setEditorState(
              htmlToState(
                descriptionHtml
                  .replace(
                    /(\w\. )([a-z])/g,
                    (match, p1, p2) => `${p1}${p2.toUpperCase()}`
                  )
                  .replace(/<br>([a-z])/g, (c) => c.toUpperCase())
                  .replace(/<p>([a-z])/g, (c) => c.toUpperCase())
              )
            ),
        },
      descriptionHtml && {
        field: "description",
        text: "Contains no double spaces.",
        right: !descriptionHtml.match(/\s{2,}/),
        fixer: () =>
          setEditorState(htmlToState(descriptionHtml.replace(/\s{2,}/g, " "))),
      },
      {
        field: "manufacturerLink",
        text: "Provides a valid link.",
        right:
          manufacturerLink &&
          manufacturerLink.match(
            /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)/
          ),
      },
      {
        field: "manufacturerLink",
        text: "Provides a link to a product-specific page.",
        right:
          manufacturerLink &&
          manufacturerLink.match(
            /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#?&//=]*)\//
          ),
      },
      {
        field: "mpn",
        text: "Provides a valid part number from the manufacturer.",
        right: mpn && mpn.length > 4 && mpn !== props.code,
      },
      {
        field: "docs",
        text: "Provides at least one piece of manufacturer documentation.",
        right: docs && docs.length > 0,
      },
      {
        field: "docs",
        text: "Provides two or more pieces of manufacturer documentation.",
        right: docs && docs.length > 1,
      },
      {
        field: "photos",
        text: "Provides at least one 'OK' (or better) photo.",
        right:
          photoJudgements &&
          photoJudgements.filter(
            (j) => j.quality === "OK" || j.quality === "Good"
          ).length > 0,
      },
      {
        field: "photos",
        text: "First photo is 'Good'.",
        right: firstPhoto && firstPhoto.quality === "Good",
      },
      {
        field: "photos",
        text: "Provides two or more 'Good' photos.",
        right:
          photoJudgements &&
          photoJudgements.filter((j) => j.quality === "Good").length > 1,
      },
    ].filter((x) => x && x["text"]);
  };

  const antecedentFeedback = generateFeedback(
    props.title,
    stateToHtml(htmlToState(props.description)),
    props.photos,
    props.mpn,
    props.manufacturerLink,
    props.docs
  );

  const currentFeedback = generateFeedback(
    currentTitle,
    stateToHtml(editorState),
    currentPhotos,
    currentMPN,
    currentManLink,
    currentDocs
  );

  // Poll backend for updated photos object
  React.useEffect(() => {
    const interval = setInterval(() => {
      fetch(`/items/${props.code}/photos`)
        .then((resp) => resp.json())
        .then((data) => {
          if (!data.errors) {
            setCurrentPhotos(data["photos"]);
          }
        });
    }, 2000);
    return () => clearInterval(interval);
  }, []);

  const handleSkip = () => {
    fetch(`/items/${props.code}/skip`, {
      method: "POST",
    })
      .then((resp) => resp.json())
      .then((data) => {
        window.location.href = `/items/${data["next"]}`;
      });
  };

  const handleSubmit = (moveOn) => {
    let formData = new FormData();

    formData.append("preFeedback", JSON.stringify(antecedentFeedback));
    formData.append(
      "preScore",
      JSON.stringify(antecedentFeedback.filter((x) => x.right).length)
    );
    formData.append("postFeedback", JSON.stringify(currentFeedback));
    formData.append(
      "postScore",
      JSON.stringify(currentFeedback.filter((x) => x.right).length)
    );

    formData.append("title", currentTitle || "");
    formData.append("bodyHtml", stateToHtml(editorState));
    formData.append("summary", currentSummary || "");
    formData.append("warranty", currentWarranty || "");
    formData.append("itemType", currentItemType || "");
    formData.append("manufacturerLink", currentManLink || "");
    formData.append("mpn", currentMPN || "");

    issues
      .filter((x) => x.checked)
      .forEach((issue, issueIndex) =>
        formData.append(`issues[${issueIndex}]`, JSON.stringify(issue.slug))
      );

    currentDocs.forEach(({ source, ...doc }, docIndex) => {
      formData.append(`docs[${docIndex}]`, JSON.stringify({ ...doc }));
      formData.append(`sources[${docIndex}]`, source);
    });

    console.log("Wanting to submit", currentSubcollections);

    currentSubcollections.forEach((subcollection, subcollectionIndex) => {
      formData.append(
        `subcollections[${subcollectionIndex}]`,
        JSON.stringify(subcollection)
      );
    });

    fetch(`/items/${props.code}`, {
      method: "PATCH",
      body: formData,
    })
      .then((resp) => resp.json())
      .then((data) => {
        if (data.errors) {
          alert(data.errors);
        } else {
          console.log(data);
          if (moveOn) {
            window.location.href = `/items/${data["next"]}`;
          } else {
            window.location.reload();
          }
        }
      });
  };

  return (
    <div className="flex flex-col items-stretch p-8 space-y-8">
      <Header
        title={props.title}
        code={props.code}
        mpn={props.mpn}
        manufacturer={props.manufacturer}
        shopifyEditorLink={
          props.shopifyId &&
          `https://weldmet.myshopify.com/admin/products/${props.shopifyId}`
        }
        shopifyViewLink={
          props.shopifyHandle &&
          `https://shop.weldmet.co.uk/products/${props.shopifyHandle}`
        }
        manufacturerLink={props.manufacturerLink}
        issues={issues}
        onChangeIssues={setIssues}
      />

      {/* Title */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2">
            <div className="flex flex-row space-x-2 items-center justify-between w-full">
              <div className="text-xl font-bold flex-grow">Product Title</div>
              {props.hansaTitle && props.hansaTitle !== currentTitle && (
                <div className="flex flex-col items-end text-right">
                  <div className="text-sm text-gray-500">
                    <b>In Hansa:</b> {props.hansaTitle}
                  </div>
                  <div
                    className="text-blue-600 underline cursor-pointer"
                    onClick={() => setCurrentTitle(props.hansaTitle)}
                  >
                    Use title from Hansa
                  </div>
                </div>
              )}
            </div>
            <PointBoard
              previousFeedback={antecedentFeedback}
              currentFeedback={currentFeedback}
              field="title"
            />
          </div>
          <input
            className="p-4 mt-4 text-2xl border-2 border-gray-400 rounded shadow"
            value={currentTitle}
            onChange={(e) => setCurrentTitle(e.target.value)}
          />
          <FeedbackMatrix feedback={currentFeedback} field="title" />
        </div>
      </div>

      <hr />

      {/* Description */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2 mb-4">
            <div className="text-xl font-bold">Description (Web-Only)</div>
            <PointBoard
              previousFeedback={antecedentFeedback}
              currentFeedback={currentFeedback}
              field="description"
            />
          </div>
          <Editor
            editorState={editorState}
            stripPastedStyles
            wrapperClassName="demo-wrapper"
            editorClassName="weldmet-editor rounded text-xl border-2 border-gray-400 shadow p-4"
            editorStyle={{ background: "white", padding: "0 20px" }}
            onEditorStateChange={onEditorStateChange}
            toolbar={{
              options: ["inline", "list", "link", "history"],
            }}
          />
          <FeedbackMatrix feedback={currentFeedback} field="description" />
        </div>
      </div>

      <hr />

      {/* Summary */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2 mb-4">
            <div className="text-xl font-bold">Summary (Optional)</div>
          </div>
          <textarea
            className="p-4 mt-4 text-xl border-2 border-gray-400 rounded shadow"
            value={currentSummary}
            rows={5}
            onChange={(e) => setCurrentSummary(e.target.value)}
          />
        </div>
      </div>

      <hr />

      {/* Photos */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2">
            <div className="text-xl font-bold">Photos</div>
            <PointBoard
              previousFeedback={antecedentFeedback}
              currentFeedback={currentFeedback}
              field="photos"
            />
          </div>
          <div className="flex flex-wrap my-4 space-x-4">
            {currentPhotos.map((photo) => (
              <div className="flex flex-col items-center justify-center bg-white p-4 shadow rounded">
                <img src={photo.src} className="h-48 mb-2" />
                <PhotoRating width={photo.width} height={photo.height} />
                <div className="text-sm text-gray-600 mt-1">
                  {photo.width} × {photo.height}
                </div>
              </div>
            ))}
          </div>

          <FeedbackMatrix feedback={currentFeedback} field="photos" />

          <div className="flex flex-col items-center justify-between p-4 mt-8 space-y-4 text-center border-2 border-green-600 rounded-lg md:text-left bg-green-50 md:flex-row md:space-x-4 md:space-y-0">
            <div className="flex flex-col items-center text-green-900 md:flex-row">
              <i className="mb-4 mr-0 text-4xl text-green-600 fa-brands fa-shopify md:mb-0 md:mr-4"></i>
              To modify images (and videos), you must use the Shopify editor
              directly. These changes are automatically tracked and will update
              here.
            </div>
            <a
              className="p-2 px-4 text-gray-800 bg-white border-2 border-gray-200 rounded shadow-sm cursor-pointer whitespace-nowrap"
              href={`https://weldmet.myshopify.com/admin/products/${props.shopifyId}`}
              target="_blank"
            >
              <i className="mr-2 text-green-700 fa-brands fa-shopify"></i>
              Edit photos in Shopify
            </a>
          </div>
        </div>
      </div>

      <hr />

      {/* Warranty */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2">
            <div className="text-xl font-bold">Warranty</div>
          </div>
          <select
            className="p-2 mt-4 text-lg border-2 border-gray-400 rounded shadow"
            value={currentWarranty ? currentWarranty : null}
            placeholder="Select a warranty..."
            onChange={(e) => setCurrentWarranty(e.target.value)}
          >
            <option value="" disabled selected>
              Select a warranty...
            </option>
            {props.warrantyTypes.map((warranty) => (
              <option value={warranty.id}>{warranty.name}</option>
            ))}
          </select>
        </div>
      </div>

      <hr />

      {/* Item Type */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2">
            <div className="text-xl font-bold">Item Type</div>
          </div>
          <select
            className="p-2 mt-4 text-lg border-2 border-gray-400 rounded shadow"
            value={currentItemType ? currentItemType : null}
            placeholder="Select an item type..."
            onChange={(e) => setCurrentItemType(e.target.value)}
          >
            <option value="" disabled selected>
              Select an item type...
            </option>
            {props.itemTypes.map((itemType) => (
              <option value={itemType.id}>{itemType.noun_singular}</option>
            ))}
          </select>
        </div>
      </div>

      <hr />

      {/* Manufacturer's link */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2">
            <div className="text-xl font-bold">
              Manufacturer's Product Webpage
            </div>
            <PointBoard
              previousFeedback={antecedentFeedback}
              currentFeedback={currentFeedback}
              field="manufacturerLink"
            />
          </div>
          <input
            className="p-4 mt-4 text-2xl border-2 border-gray-400 rounded shadow"
            value={currentManLink}
            onChange={(e) => setCurrentManLink(e.target.value)}
          />
          <FeedbackMatrix feedback={currentFeedback} field="manufacturerLink" />
        </div>
      </div>

      <hr />

      {/* MPN */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2">
            <div className="text-xl font-bold">
              Manufacturer's Part Number (MPN)
            </div>
            <PointBoard
              previousFeedback={antecedentFeedback}
              currentFeedback={currentFeedback}
              field="mpn"
            />
          </div>
          <input
            className="p-4 mt-4 text-2xl border-2 border-gray-400 rounded shadow"
            value={currentMPN}
            onChange={(e) => setCurrentMPN(e.target.value)}
          />
          <FeedbackMatrix feedback={currentFeedback} field="mpn" />
        </div>
      </div>

      <hr />

      {/* Subcollections */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="text-xl font-bold">Subcollections</div>
          <Subcollections
            subcollections={currentSubcollections}
            onChange={(updatedValue) => setCurrentSubcollections(updatedValue)}
          />
        </div>
      </div>

      <hr />

      {/* Docs */}
      <div className="flex items-start justify-between">
        <div className="flex flex-col flex-grow">
          <div className="flex items-center space-x-2">
            <div className="text-xl font-bold">Documentation</div>
            <PointBoard
              previousFeedback={antecedentFeedback}
              currentFeedback={currentFeedback}
              field="docs"
            />
          </div>
          <DocList
            docs={currentDocs}
            onChange={(updatedValue) => setCurrentDocs(updatedValue)}
            docTypes={props.docTypes}
          />
          <FeedbackMatrix feedback={currentFeedback} field="docs" />
        </div>
      </div>

      <hr />

      <div className="flex flex-col justify-center md:justify-between mt-8 space-y-4 md:flex-row md:space-y-0 md:space-x-4 whitespace-nowrap">
        {!props.direct && (
          <button
            onClick={() => handleSkip()}
            className="py-5 text-2xl font-normal bg-orange-400 green-btn btn"
          >
            Skip item<i className="ml-4 text-white fa fa-forward"></i>
          </button>
        )}
        <div className="flex flex-col justify-center md:justify-between mt-8 space-y-4 md:flex-row md:space-y-0 md:space-x-4 whitespace-nowrap">
          <button
            onClick={() => handleSubmit(false)}
            className={`py-5 text-2xl font-normal ${
              props.direct ? "text-white" : "text-gray-700 bg-white"
            } green-btn btn`}
          >
            Save changes
            <i
              className={`ml-4 ${
                props.direct ? "text-white" : "text-gray-400"
              } fa fa-save`}
            ></i>
          </button>
          {!props.direct && (
            <button
              onClick={() => handleSubmit(true)}
              className="py-5 text-2xl font-normal green-btn btn"
            >
              Save changes and next item
              <i className="ml-4 text-white fa fa-arrow-right"></i>
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

export default ItemPage;
